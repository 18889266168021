const links = () => [
  {
    url: 'aboutMe',
    text: 'About',
  },
  {
    url: 'myServices',
    text: 'Services',
  },
  {
    url: 'projects',
    text: 'Projects',
  },
  {
    url: 'contactMe',
    text: 'Contact',
  },
];

export default links;
